import { CoinbaseWalletProvider, CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import EthereumWalletConnectProvider from '@walletconnect/ethereum-provider';
import Web3WalletConnectProvider from '@walletconnect/web3-provider';
import { mixins } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/manifold-sdk';
import { AbstractProvider, InjectedProvider } from '@/common/constants';
import WalletMixin from '@/mixins/wallet';

export default class MultiWalletMixin extends mixins(WalletMixin) {
  injectedWallet = false;

  async connectBrowserWallet(name: string, provider: InjectedProvider): Promise<void> {
    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithEthereumProvider(false, provider);
      localStorage.setItem('connectMethod', name);
      this.injectedWallet = true;
    } catch {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    }
  }

  /**
   * Helper to establish wallet connect connection
   */
  async connectWalletConnect(): Promise<void> {
    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithWalletConnect();
    } catch (error) {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    }
  }

  async connectCoinbaseWallet(): Promise<void> {
    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithCoinbaseWallet();
    } catch (error) {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    }
  }

  async _automaticallyReconnect(): Promise<void> {
    this._automaticallyConnectMultiWallet();
  }

  async _automaticallyConnectMultiWallet(): Promise<void> {
    // Reconnect if
    // 1. autoReconnect is set
    // 2. we have a connected address in local storage
    if (this.autoReconnect && localStorage.getItem('connectedAddress')) {
      const connectMethod = localStorage.getItem('connectMethod');
      // make sure to reconnect using the correct connection method
      if (connectMethod === 'walletConnect') {
        await this._connectWithWalletConnect();
      } else if (connectMethod === 'coinbase') {
        await this._connectWithCoinbaseWallet();
      } else if (connectMethod && this.getBrowserWallet(connectMethod)) {
        await this._connectWithEthereumProvider(true, this.getBrowserWallet(connectMethod));
        this.injectedWallet = true;
      }
    }
  }

  async _connectWithWalletConnect(): Promise<void> {
    // if they did not provider a specific wallet provider, use the fallback
    const providerURI = this.getProviderURI();

    // create the separate WC provider and use it as the _signingProvider in EthereumProvider
    const wcProvider =
      providerURI && this.network
        ? new Web3WalletConnectProvider({
            rpc: { [this.network.valueOf()]: providerURI }
          })
        : new EthereumWalletConnectProvider({ chainId: this.network });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((wcProvider as any).signer) {
      // disconnect if they just close modal and select nothing for WC (reject from app)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const wcProviderSignerConnection = (wcProvider as any).signer.connection;
      wcProviderSignerConnection.on('disconnect', () => {
        // Force disconnect (no need to disconnect provider)
        this._disconnect(true, true);
      });
      wcProviderSignerConnection.on('close', () => {
        // Force disconnect (no need to disconnect provider)
        this._disconnect(true, true);
      });
    }
    wcProvider.connector.on('disconnect', () => {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    });
    wcProvider.connector.on('close', () => {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    });

    try {
      await wcProvider.enable();
      const wcSigningProvider = new Web3Provider(wcProvider);
      await EthereumProvider.setSigningProvider(wcSigningProvider);
      localStorage.setItem('connectMethod', 'walletConnect');
      this.injectedWallet = false;
    } catch (e) {
      console.error(e);
      // Force disconnect (no need to disconnect provider) if they just close modal and select nothing for WC
      this._disconnect(true, true);
    }
  }

  async _connectWithCoinbaseWallet(): Promise<void> {
    // @dev: see if we have injected coinbase wallet
    const windowEthereum = window.ethereum as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    const injectedCoinbaseWallet = windowEthereum?.isCoinbaseWallet
      ? windowEthereum
      : windowEthereum?.providers?.find((p: { isCoinbaseWallet: boolean }) => !!p.isCoinbaseWallet);

    try {
      if (injectedCoinbaseWallet) {
        // Trigger connect for injected wallet
        await this._connectWithEthereumProvider(false, injectedCoinbaseWallet);
        localStorage.setItem('connectMethod', 'coinbase');
      } else {
        // Trigger coinbase connect
        const coinbaseSDK = new CoinbaseWalletSDK({
          appName: this.appName
        });
        const coinbaseProvider = coinbaseSDK.makeWeb3Provider(this.getProviderURI() ?? 'undefined');
        await coinbaseProvider.enable();
        await EthereumProvider.setSigningProvider(
          new Web3Provider(coinbaseProvider as unknown as ExternalProvider)
        );
        localStorage.setItem('connectMethod', 'coinbase');
        this.injectedWallet = false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.message !== 'User denied account authorization') {
        console.error(`Unknown Coinbase Wallet error`, e);
      }
      // Force disconnect (no need to disconnect provider), which happens if they just close modal and do nothing for coinbase wallet
      this._disconnect(true, true);
    }
  }

  getProviderURI(): string | undefined {
    let providerURI = this.fallbackProvider;

    if (providerURI) {
      // modify the provider URI syntax so that it always works with WalletConnect
      const infuraMatch = providerURI.match(
        /^(wss:\/\/)(.*\.infura.io)(\/ws)(\/v[0-9]+\/[0-9a-f]+)$/
      );
      if (infuraMatch) {
        providerURI = `https://${infuraMatch[2]}${infuraMatch[4]}`;
      } else {
        providerURI = providerURI.replace('wss://', 'https://');
        providerURI = providerURI.replace('ws://', 'http://');
      }
    }
    return providerURI;
  }

  /**
   * Overrides _disconnect found in all the mixins
   */
  _disconnect(skipProviderDisconnect?: boolean, force = false): void {
    this._disconnectMultiWallet(skipProviderDisconnect);
    this._disconnectBase(skipProviderDisconnect, force);
  }

  _disconnectMultiWallet(skipProviderDisconnect = false): void {
    if (this.walletConnected) {
      // remove local storage
      localStorage.removeItem('connectMethod');

      const signingProvider = EthereumProvider.provider(true) as AbstractProvider;

      // Handle bridged provider connections
      if (!skipProviderDisconnect && signingProvider) {
        if (
          signingProvider.provider instanceof Web3WalletConnectProvider ||
          signingProvider.provider instanceof EthereumWalletConnectProvider
        ) {
          try {
            signingProvider.provider.disconnect();
          } catch (error) {
            // eat the error as it's not critical if this step goes awry
            console.warn(error);
          }
        } else if (signingProvider.provider instanceof CoinbaseWalletProvider) {
          try {
            signingProvider.provider.disconnect();
          } catch (error) {
            // eat the error as it's not critical if this step goes awry
            console.warn(error);
          }
        }
      }
    }
  }
}
