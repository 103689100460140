import { Web3Provider } from '@ethersproject/providers';
import { formatEther } from '@ethersproject/units';
import { mixins, Options } from 'vue-class-component';
import { EthereumNetwork, EthereumProvider } from '@manifoldxyz/manifold-sdk';
import { detectProvider } from '@manifoldxyz/studio-app-sdk';
import {
  AbstractProvider,
  ETHEREUM_NETWORK_COLORS,
  InjectedProvider,
  REAUTHENTICATE
} from '@/common/constants';
import web3TransactionErrorHandling, {
  transactionError,
  TransactionErrors
} from '@/common/web3TransactionErrorHandling';
import { MConnectInjectPropsMixin } from '@/exports/MConnectProps';

interface BrowserWallet {
  name: string;
  network: number | undefined;
  wrongChain: boolean;
  provider: InjectedProvider;
}

@Options({
  watch: {
    walletAddressFull: async function () {
      await this.updateBalance();
    }
  }
})
export default class WalletMixin extends mixins(MConnectInjectPropsMixin) {
  // variables that are not injected and defined only here
  badConfiguration: string | null | undefined = null;
  providerAvailable = false;
  walletAddressFull: string | undefined = '';
  walletAddressShort: string | undefined = '';
  walletENS: string | undefined = '';
  walletAvailable = !!(window && window.ethereum);
  walletBalance: string | undefined = '';
  walletConnected = false;
  wrongChain = false;
  isLoading = false;
  chainInfo: {
    name: string;
    color: typeof ETHEREUM_NETWORK_COLORS;
  } | null = null;
  browserWallets: BrowserWallet[] = [];

  get buttonText(): string {
    return this.isLoading
      ? 'Logging in...'
      : this.overrideConnectText
      ? this.overrideConnectText
      : 'Connect Wallet';
  }

  /**
   * This is the default browser wallet
   */
  get defaultBrowserWalletName(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((EthereumProvider as any)._browserProvider) {
      // should return browser provider if available
      const providerName = this.getBrowserWalletName(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((EthereumProvider as any)._browserProvider as AbstractProvider).provider
      );
      if (providerName) return providerName;
    }
    const userAgent = navigator.userAgent;
    if (userAgent.match(/edg/i)) {
      return 'Edge';
    } else if (userAgent.match(/firefox|fxios/i)) {
      return 'Firefox';
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      return 'Chrome';
    } else if (userAgent.match(/safari/i)) {
      return 'Safari';
    } else if (userAgent.match(/opr\//i)) {
      return 'Opera';
    }
    return 'MetaMask';
  }

  async computeAllBrowserWallets(): Promise<void> {
    const wallets: BrowserWallet[] = [];
    const walletNames: Set<string> = new Set();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((EthereumProvider as any)._browserProvider) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaultBrowserProvider = (EthereumProvider as any)._browserProvider as AbstractProvider;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaultBrowserNetwork = (EthereumProvider as any)._browserChainId;

      wallets.push({
        name: this.defaultBrowserWalletName,
        network: defaultBrowserNetwork,
        wrongChain: EthereumProvider.network()
          ? defaultBrowserNetwork !== EthereumProvider.network()
          : false,
        provider: defaultBrowserProvider.provider
      });
      walletNames.add(this.defaultBrowserWalletName);
      const windowEthereum = window.ethereum as InjectedProvider;
      if (windowEthereum?.providers) {
        for (const provider of windowEthereum.providers) {
          const providerName = this.getBrowserWalletName(provider);
          if (providerName && !walletNames.has(providerName)) {
            const providerNetwork = (await new Web3Provider(provider).getNetwork()).chainId;
            wallets.push({
              name: providerName,
              network: providerNetwork,
              wrongChain: EthereumProvider.network()
                ? providerNetwork !== EthereumProvider.network()
                : false,
              provider: provider
            });
          }
        }
      }
    }
    this.browserWallets = wallets;
  }

  getBrowserWalletName(provider: InjectedProvider): string | undefined {
    if (provider.isCoinbaseWallet) {
      return 'Coinbase Wallet';
    } else if (provider.isBraveWallet) {
      return 'Brave';
    } else if (provider.isLedgerConnect) {
      return 'Ledger Connect';
    } else if (provider.isBitski) {
      return 'Bitski';
    } else if (provider.isMetaMask) {
      return 'MetaMask';
    }
    return undefined;
  }

  getBrowserWallet(name: string): InjectedProvider | undefined {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((EthereumProvider as any)._browserProvider) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const abstractProvider = (EthereumProvider as any)._browserProvider as AbstractProvider;
      if (abstractProvider.provider.providers) {
        if (name === 'MetaMask') {
          return (
            abstractProvider.provider.providers.find(
              (p) => !!p.isMetaMask && !p.overrideIsMetaMask
            ) || abstractProvider.provider
          );
        } else if (name === 'Bitski') {
          return (
            abstractProvider.provider.providers.find((p) => !!p.isBitski) ||
            abstractProvider.provider
          );
        } else if (name === 'Ledger Connect') {
          return (
            abstractProvider.provider.providers.find((p) => !!p.isLedgerConnect) ||
            abstractProvider.provider
          );
        } else if (name === 'Brave') {
          return (
            abstractProvider.provider.providers.find((p) => !!p.isBraveWallet) ||
            abstractProvider.provider
          );
        } else if (name === 'Coinbase Wallet') {
          return (
            abstractProvider.provider.providers.find((p) => !!p.isCoinbaseWallet) ||
            abstractProvider.provider
          );
        }
      }
      return abstractProvider.provider;
    }
    return undefined;
  }

  async created(): Promise<void> {
    window.manifold = {};

    // Get address by pulling information from EthereumProvider, because the widget may be created
    // AFTER the event is fired
    const address = EthereumProvider.selectedAddress();

    if (address) {
      // It means we connected before creating this widget
      this.providerAvailable = true;
      localStorage.setItem('connectedAddress', address);
      // Set up the wallet address state
      await this.onAddressChanged();
      await this.updateBalance();
    }

    window.addEventListener(EthereumProvider.ADDRESS_CHANGED, this.onAddressChanged);
    window.addEventListener(REAUTHENTICATE, this.onReauthenticate);
    // We set up the provider and chain listeners afterwards to avoid
    // situations where a provider change can trigger a
    // concurrent auto-reconnect with the above.
    window.addEventListener(EthereumProvider.PROVIDER_CHANGED, this.onProviderChanged);
    window.addEventListener(EthereumProvider.CHAIN_CHANGED, this.onChainChanged);

    await this.updateChainInfo();
  }

  destroyed(): void {
    window.removeEventListener(EthereumProvider.PROVIDER_CHANGED, this.onProviderChanged);
    window.removeEventListener(EthereumProvider.ADDRESS_CHANGED, this.onAddressChanged);
    window.removeEventListener(EthereumProvider.CHAIN_CHANGED, this.onChainChanged);
  }

  async mounted(): Promise<void> {
    if (!this.network && this.fallbackProvider) {
      this.badConfiguration = 'Config Error';
      throw new Error('fallbackProvider should not be configured on network agnostic connections.');
    } else {
      if (!!EthereumProvider.network() && this.network !== EthereumProvider.network()) {
        console.warn(
          'An older EthereumProvider was initialized with different inputs, your input for the current connect-widget will be ignored'
        );
      }

      if (this.parentFrameUrl) {
        // In iframe, we need to detect app bridge provider first
        let appBridgeProvider;
        console.debug(
          `Parent frame URL configured to '${this.parentFrameUrl}', using app bridge provider`
        );
        try {
          appBridgeProvider = await detectProvider(this.parentFrameUrl);
        } catch (e) {
          console.debug('Error detecting app bridge provider:', e);
        }
        if (appBridgeProvider) {
          await EthereumProvider.initialize(
            this.network,
            this.fallbackProvider,
            undefined,
            appBridgeProvider
          );
        } else {
          // fallback to regular in-frame provider if app bridge cannot be established
          console.warn('No app bridge provider available');
          await EthereumProvider.initialize(this.network, this.fallbackProvider);
        }
      } else {
        await EthereumProvider.initialize(this.network, this.fallbackProvider);
      }

      await this._automaticallyReconnect();
      this._refreshBrowserWalletState();

      // whenever they complete a tx lets update the UX of their eth balance
      window.addEventListener('transactions-confirmed-event', async () => {
        await this.updateBalance();
      });
    }
  }

  /**
   * Handles button triggered connect
   */
  async connectDefaultBrowserWallet(_event: Event): Promise<void> {
    try {
      this.isLoading = true;
      await this._connectWithEthereumProvider(
        false,
        this.getBrowserWallet(this.defaultBrowserWalletName)
      );
    } catch (error) {
      // Force disconnect (no need to disconnect provider as it failed to connect)
      this._disconnect(true, true);
    }
  }

  /**
   * Connects to web3 and updates all chain/wallet related info upon success.
   */
  async _connectWithEthereumProvider(
    autoReconnect = false,
    browserProviderOverride?: unknown
  ): Promise<void> {
    try {
      await EthereumProvider.connect(browserProviderOverride);
    } catch (error) {
      if (!autoReconnect) {
        const transactionErrors = web3TransactionErrorHandling(error as transactionError);
        switch (transactionErrors) {
          case TransactionErrors.REJECTED: {
            // Force disconnect (no need to disconnect provider as it failed to connect)
            this._disconnect(true, true);
            break;
          }
          case TransactionErrors.LEDGER_ERROR: {
            // Force disconnect (no need to disconnect provider as it failed to connect)
            this._disconnect(true, true);
            break;
          }
          case TransactionErrors.PENDING: {
            alert(`Please open ${this.defaultBrowserWalletName} Wallet to continue.`);
            break;
          }
          default: {
            alert(
              `Could not connect to ${this.defaultBrowserWalletName}, please try refreshing your page. If you continue to have issues, try closing your browser and re-opening it.`
            );
            break;
          }
        }

        throw error;
      }
    }
  }

  /**
   * Disconnects from web3 and deletes our Oauth cookie for the JSON API.
   */
  disconnectWallet(): void {
    this._disconnect();
  }

  /**
   * Disconnects from web3
   *
   * @param skipProviderDisconnect  - Do not disconnect the provider.
   * @param force                   - Force disconnect and reset even if there is no connected wallet
   */
  _disconnect(skipProviderDisconnect = false, force = false): void {
    this._disconnectBase(skipProviderDisconnect, force);
  }

  /**
   * This function should be called by any mixin that overrides _disconnect
   *
   * @param skipProviderDisconnect  - Do not disconnect the provider.
   * @param force                   - Force disconnect and reset even if there is no connected wallet
   */
  _disconnectBase(skipProviderDisconnect = false, force = true): void {
    if (this.walletConnected || force) {
      localStorage.removeItem('connectedAddress');
      this.walletAddressFull = undefined;
      this.walletAddressShort = undefined;
      this.walletENS = undefined;
      this.walletBalance = undefined;
      this.walletConnected = false;
      window.manifold = {
        isAuthenticated: false,
        address: '',
        dataClient: undefined,
        oauthToken: undefined
      };
      this.isLoading = false;
      if (!skipProviderDisconnect) {
        EthereumProvider.disconnect();
      }
    }
  }

  /**
   * Connects to web3 only if this.automaticallyReconnect is set and we
   * are already connected with a valid wallet address.
   */
  async _automaticallyReconnect(): Promise<void> {
    // Reconnect if
    // 1. autoReconnect is set
    // 2. we have a connected address in local storage
    // 3. we have a provider (only available if no network or network is correct
    if (
      this.autoReconnect &&
      localStorage.getItem('connectedAddress') &&
      EthereumProvider.provider()
    ) {
      // Provider only available if no network or network is correct
      if (!EthereumProvider.selectedAddress()) {
        // No address and address listener setup
        // Run auto-reconnect, which will trigger the address
        // changed callback and set up the appropriate state
        await this._connectWithEthereumProvider(true);
      }
    }
  }

  /**
   * Updates the current eth balance of the wallet being displayed.
   * Call this whenever the adddress, chain, or provider is changed.
   */
  async updateBalance(): Promise<void> {
    const provider = EthereumProvider.provider();
    try {
      if (this.walletAddressFull && provider) {
        const balanceString = (await provider.getBalance(this.walletAddressFull)).toString();
        const ethValue = formatEther(balanceString);
        const with3Decimals = ethValue.match(/^-?\d+(?:\.\d{0,3})?/);
        // with3Decimals looks like this (does not round!): [ '0.017', index: 0, input: '0.017926361227063654', groups: undefined ]
        if (with3Decimals && with3Decimals.length > 0) {
          this.walletBalance = with3Decimals[0];
        } else {
          this.walletBalance = undefined;
        }
      } else {
        this.walletBalance = undefined;
      }
    } catch (e) {
      // Error getting wallet balance
      console.warn(`Error getting wallet balance`, e);
    }
  }

  /**
   * Updates the name and corresponding color for chainInfo. Call this
   * whenever the chain information may have updated.
   */
  updateChainInfo(): void {
    /* NOTE possibility that there is duplicate functionality here
     * between this method and badConfiguration variable.
     */
    const chainId: number | undefined = EthereumProvider.chainId();
    this.wrongChain = !EthereumProvider.chainIsCorrect();
    if (chainId) {
      this.chainInfo = {
        name: EthereumNetwork[chainId],
        color: ETHEREUM_NETWORK_COLORS[chainId]
      };
      this.providerAvailable = true;
    } else {
      // No network provider or valid provider for specified network.
      this.chainInfo = null;
      this.providerAvailable = false;
    }
  }

  /**
   * Fires when reauthenication is requested again
   */
  async onReauthenticate(): Promise<void> {
    await this._authenticate(true);
  }

  /**
   * Fires when the address is changed
   */
  async onAddressChanged(): Promise<void> {
    await this._authenticate();
  }

  /**
   * Authentication helper
   *
   * First it updates all chainInfo.
   * Then it updates everything related to the wallet adddress and ens name
   * if possible. Finally it stores the wallet address as "connectAddress"
   * inside of local storage. If ther ewas an issue or the address is now
   * undefined/null, we clear all wallet related vlaues and clear localStorage
   * of the "connectedAddress" itme.
   */
  async _authenticate(force = false): Promise<void> {
    this.badConfiguration = null;
    this.updateChainInfo();
    const address = EthereumProvider.selectedAddress();
    const ens = EthereumProvider.selectedENSName();
    if (force || address !== this.walletAddressFull || ens !== this.walletENS) {
      // Reset current state via disconnect (no need to disconnect the provider or force disconnect)
      this._disconnect(true);

      this.walletAddressFull = address;
      this.walletENS = ens;
      if (address) {
        try {
          const addressLength = address.length;
          const retval =
            address.slice(0, 6) + '...' + address.slice(addressLength - 4, addressLength);
          this.walletAddressShort = retval;
          this.walletConnected = true;
          localStorage.setItem('connectedAddress', address);
        } catch (error) {
          const transactionErrors = web3TransactionErrorHandling(error as transactionError);
          switch (transactionErrors) {
            case TransactionErrors.REJECTED: {
              // Force disconnect (no need to disconnect provider)
              this._disconnect(true, true);
              break;
            }
            case TransactionErrors.LEDGER_ERROR: {
              // Force disconnect (no need to disconnect provider)
              this._disconnect(true, true);
              break;
            }
            case TransactionErrors.PENDING: {
              alert(`Please open ${this.defaultBrowserWalletName} Wallet to continue.`);
              break;
            }
            default: {
              alert('There was an issue with that wallet connection');
              break;
            }
          }
        }
      } else {
        // No address, no state
        this._disconnect(true);
      }
      this.isLoading = false;
    }
  }

  /**
   * Fires when the chain is changed
   * Ensures the chainInfo is updated then updates the balance we see.
   */
  async onChainChanged(): Promise<void> {
    this.badConfiguration = null;

    // There is a case where the auto-reconnect didn't work on initialization
    // because the provider was not available yet.  This will cause a chain change
    // event once it becomes available, so try auto-connection when this happens
    await this._automaticallyReconnect();
    this._refreshBrowserWalletState();
    await this.updateBalance();
  }

  /**
   * Fires when the provider is changed
   * Ensures that the chainInfo is updated then automatically reconnnects.
   */
  async onProviderChanged(): Promise<void> {
    this.badConfiguration = null;
    this.walletAvailable = !!(window && window.ethereum);
    this._refreshBrowserWalletState();
    await this._automaticallyReconnect();
  }

  _refreshBrowserWalletState(): void {
    this.walletAvailable = !!(window && window.ethereum);
    this.updateChainInfo();
    this.computeAllBrowserWallets();
  }

  /*
   * Helpful in every view for when users need to install a wallet
   */
  openMetamaskLink(): void {
    window.open('https://metamask.io', '_blank');
  }
}
